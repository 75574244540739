import React from "react";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import TopBanner from "../../../components/database/topbanner/TopBanner";

import yugabyteImg from "../../../components/database/images/yugabyte_large.png";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/yugabyte_rest.png",
};

const canonicalUrl = "https://hasura.io/rest-api/database/yugabyte/";

const Yugabyte = props => (
  <Layout location={props.location}>
    <Seo
      title="Instant REST APIs on Yugabyte | Hasura REST API"
      description="Hasura connects to existing Yugabyte database and gives instant REST CRUD APIs with Authorization."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <TopBanner
      title="Instant REST API for Yugabyte"
      desc="Hasura supports Yugabyte and gives instant REST APIs"
      btnLink="https://cloud.hasura.io/signup?pg=yugabyte&plcmt=body&cta=try-it-out-in-30-seconds&tech=default"
      btnText="Try it Out in 30s"
      imgSrc={yugabyteImg}
      linkUrl="https://hasura.io/docs/latest/graphql/cloud/getting-started/cloud-databases/yugabyte.html"
      linkText="Check out the docs here"
    />
  </Layout>
);

export default Yugabyte;
